import React from "react";
import { motion, AnimatePresence } from "framer-motion";

import "./FeaturesRust.scss";

export default function FeaturesRust({rerenderValue}) {

    let featuresRustRef = React.useRef();
    let featureContentRef = React.useRef();
    let [height, setHeight] = React.useState(0);
    let [showToggle, setShowToggle] = React.useState(true);

    React.useEffect(() => {
        function getShowHeight(){
            let ref = featureContentRef.current;
            let currentHeight = ref.scrollHeight
            setHeight(currentHeight);
        }
        getShowHeight()
        window.addEventListener("resize", getShowHeight);
        return () => window.removeEventListener("resize", getShowHeight);
    }, [])

    React.useEffect(() => {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if(entry.isIntersecting === true) {
                    let classList = entry.target.className;
                    let newClassName = classList.replaceAll(" active", "");
                    entry.target.className = newClassName + " active";
                    observer.unobserve(entry.target);
                }
            })  
        }, {threshold: 0.3})
        
        observer.observe(featuresRustRef.current)
    }, [])

    return(
        <div className="features-rust box">
            <div className="container" style={{maxHeight: `${showToggle ? "320px" : height + 76 +"px"}`}} ref={featuresRustRef}>
                <div className="features-title">
                    Functionality
                </div>
                <div className="feature-content-container" ref={featureContentRef}>
                    <div className="feature-content-box" id-animation="1">
                        <div className="feature-content-title">
                           Aim (Main)
                        </div>
                        <div className="feature-content-features">
                            {`- Vector
                            - Draw Field of View
                            - Team Check
                            - Snapline
                            - Field of view (FOV)
                            - Aimbot speed
                            - Aim Visible Check
                            - Aim NPC
                            - Target Lock
                            - Target Bone`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-aim-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                    <div className="feature-content-box" id-animation="2">
                        <div className="feature-content-title">
                            Aim (Advanced)
                        </div>
                        <div className="feature-content-features">
                            {`- Zoom
                            - Zoom Field of View
                            - Debug camera (BAN RISK)
                            - Default Speed
                            - Slow multipler speed
                            - Sprint multipler speed`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-weapon-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                    <div className="feature-content-box" id-animation="3">
                        <div className="feature-content-title">
                            Player ESP
                        </div>
                        <div className="feature-content-features">
                            {`- Look at warning
                            - Out field of view
                            - Invisible Check
                            - Skeleton
                            - Sleepers
                            - Distance
                            - Ghost
                            - Team ID
                            - Team ID Numbers
                            - Team
                            - Box (2D/Corner)
                            - Help Weapon
                            - Belt
                            - Crosshair`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-visuals-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                    <div className="feature-content-box" id-animation="4">
                        <div className="feature-content-title">
                            Player ESP (NPC)
                        </div>
                        <div className="feature-content-features">
                            {`- NPC Esp
                            - Look at Warning
                            - Out field of view (OutFov)
                            - Invisible Check
                            - Skeleton
                            - Distance
                            - Box (2D/Corner)
                            - Help Weapon
                            - Belt`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-esp-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                    <div className="feature-content-box" id-animation="5">
                        <div className="feature-content-title">
                            World (Main)
                        </div>
                        <div className="feature-content-features">
                            {`- Disable All (Battle mode)
                            - Events indicator
                            - Backpack
                            - Player corpse
                            - Hemp
                            - Food
                            - Bullet Markers
                            - Raid Esp
                            - Max time Raid Esp
                            - Max Distance Raid Esp
                            - Dropped items`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-settings-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                    <div className="feature-content-box" id-animation="6">
                        <div className="feature-content-title">
                            World (Resources & Animals)
                        </div>
                        <div className="feature-content-features">
                            {`- Stone Collectable
                            - Sulfur Collectable
                            - Metal Collectable
                            - Stone Ore
                            - Sulfur Ore
                            - Metal Ore
                            - Stag
                            - Wolf
                            - Bear
                            - Chicken
                            - Boar
                            - Ridable Horse
                            - Diesel
                            - Supply Drop`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-settings-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                    <div className="feature-content-box" id-animation="7">
                        <div className="feature-content-title">
                            World (Traps)
                        </div>
                        <div className="feature-content-features">
                            {`- Auto Turrets
                            - Guntrap
                            - Flame Turrets
                            - SamSite
                            - LandMine`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-settings-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                    <div className="feature-content-box" id-animation="8">
                        <div className="feature-content-title">
                            World (Crates)
                        </div>
                        <div className="feature-content-features">
                            {`- Food crate
                            - Elite crate
                            - Normal crate
                            - Heli crate
                            - Military crate
                            - Bradley crate
                            - Hackable crate
                            - Tools crate`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-settings-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                    <div className="feature-content-box" id-animation="9">
                        <div className="feature-content-title">
                            World 2 (Transport)
                        </div>
                        <div className="feature-content-features">
                            {`- Patrol Helicopter
                            - Bradley APC
                            - Minicopter
                            - Scrap Helicopter
                            - Attack Helicopter
                            - Modular Car
                            - Drone
                            - RowBoat
                            - TugBoat
                            - Rhib
                            - Train
                            - Snowmobile
                            - Bike
                            - Motorbike`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-settings-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                    <div className="feature-content-box" id-animation="10">
                        <div className="feature-content-title">
                            World 2 (Buildings)
                        </div>
                        <div className="feature-content-features">
                            {`- Turn off Buildings
                            - Bags
                            - Beds
                            - Wooden box
                            - Large box
                            - Coffin box
                            - Door armored
                            - Door Armored Double
                            - Door Sheet
                            - Door sheet double
                            - Door Garage
                            - Battery small
                            - Battery Large
                            - Workbench 2
                            - Workbench 3
                            - Buildings (Walls,Floor)
                            - Ladder hatch
                            - Furnace
                            - Vending Machine
                            - Tool cupboard
                            - Glass Strength
                            - Glass Reinforced`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-settings-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                    <div className="feature-content-box" id-animation="11">
                        <div className="feature-content-title">
                            Settings (Menu Color)
                        </div>
                        <div className="feature-content-features">
                            {`- Background
                            - Tab background
                            - Text
                            - Text active
                            - Left Bar
                            - Tab text
                            - Tab frame
                            - Slider
                            - Slider disabled
                            - Slider frame
                            - Slider frame active`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-settings-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                    <div className="feature-content-box" id-animation="12">
                        <div className="feature-content-title">
                            Configs
                        </div>
                        <div className="feature-content-features">
                            {`- Main
                            - Default #1
                            - Default #2
                            - Default #3`.replaceAll("/", " / ").split("- ").map((e,i) => {
                                if(i === 0 ) return
                                return <span key={"feature-settings-"+i}>{"-" + e}</span>
                            })}
                        </div>    
                    </div>
                </div>
                <div className="show-more-less">
                    <span onClick={() => {
                        setShowToggle(!showToggle);
                        rerenderValue();
                    }}>
                        <AnimatePresence mode="wait">
                            <motion.span
                                initial = {{opacity: 0}}
                                animate = {{opacity: 1}}
                                exit = {{opacity: 0}}
                                key={showToggle}
                                >
                                {showToggle ? "Show More" : "Show Less"}
                            </motion.span>
                        </AnimatePresence>

                    </span>
                </div>
            </div>
        </div>
    )
}